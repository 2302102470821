<template>
  <v-row>
    <v-col lg="12">
      <s-crud title="Propiedades de Configuración" edit add remove restore sortable search-input :config="this.config" @save="savePro($event)">
        <template slot-scope="props">
          <v-container v-if="props.item != null">
            <v-row justify="center">
              
              <v-col cols="6" md="4" xs="6" class="s-col-form">
                <s-select-transaction
                  v-model="PropertyTable"
                  returnObject
                  
                  @input="SelectTable(props.item)"
                ></s-select-transaction>
               
              </v-col>
              <v-col cols="6" md="2" xs="6" class="s-col-form">
                <v-label><b>Campo</b></v-label>
                <v-autocomplete
                  v-model="props.item.ProName"
                  item-value="GenDescription"
                  item-text="GenDescription"
                  :items="tablesparameters"
                  dense
                  outlined
                  hide-details
                  clearable
                />
              </v-col>
              <v-col cols="6" md="2" xs="6" class="s-col-form">
                <s-select-definition :def="1028" label="Tipo Valor" v-model="props.item.TypeValue" />
              </v-col>
              <v-col cols="12" md="8" xs="12" class="s-col-form">
                <s-text v-model="props.item.ProDescription" label="Descripcion del Campo" />
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:SecStatus="{ row }">
          <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
            {{ row.SecStatus == 1 ? "Activo" : "Baja" }}
          </v-chip>
        </template>
      </s-crud>
    </v-col>
  </v-row>
</template>
<script>
import _sProperty from "@/services/General/PropertysService";
import _sGenericService from "./../../services/General/GenericService";
export default {
  components: {},
  data: () => ({
    tablesparameters: [],
    config: {
      model: {
        ProID: "ID",
        ObjectType: "int",
        ProTableName: "string",
        ProName: "string",
        TypeValue: "int",
        ProDescription: "string",
        SecStatus: "status",
      },
      service: _sProperty,

      headers: [
        {
          text: "ID",
          sortable: false,
          value: "ProID",
          width: "15%",
        },
        {
          text: "Tabla",
          value: "ProTableName",
          sortable: true,
        },
        {
          text: "Nombre Campo",
          value: "ProName",
          width: "20%",
        },
        {
          text: "Valor Campo",
          value: "TypeValueText",
          width: "20%",
        },
        {
          text: "Estado",
          value: "SecStatus",
          sortable: true,
          align: "center",
          width: "15%",
        },
      ],
    },
    PropertyTable: {},
    Selected: "",
  }),
  methods: {
    initialize() {
      _sGenericService.searchPropertyValues(this.Selected, this.$fun.getUserID()).then((r) => {
        this.tablesparameters = r.data;
      });
    },
    SelectTable() {
      this.Selected = this.PropertyTable.GenHelper;
      if (this.Selected != null) this.initialize();
    },
    validatedPro(item) {
      let isValid = false;
      let message = "";

      isValid = item.ProTableName.length > 0;

      if (!isValid) message = "Ingrese nombre de tabla";
      else {
        isValid = item.ProName.length > 0;
        if (!isValid) message = "Seleccione campo";
        else {
          isValid = item.TypeValue > 0;
          if (!isValid) message = "Seleccione el tipo de valor";
          else {
            isValid = item.ProDescription.length > 0;
            if (!isValid) message = "Registre la descripcion";
          }
        }
      }

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    savePro(item) {
      item.ProTableName = this.Selected;
      console.log(item);
      if (this.validatedPro(item)) {
        item.save();
        this.initialize();
      }
    },
  },
};
</script>
